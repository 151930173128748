import classNames from 'classnames';
import Image from 'next/image';
import React, { ChangeEvent, useState } from 'react';
import visibility_off from '../../public/images/icon-visibility_off.svg?url';
import visibility_on from '../../public/images/icon-visibility_on.svg?url';

import ErrorMessage from 'components/ErrorMessage';
import st from './NewInput.module.scss';

const uniqueId = (prefix = '') =>
  `${prefix}${Math.random().toString(36).substring(2, 9)}`;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  errorMessage?: string;
  label?: string;
}

export default function Input({
  type = 'text',
  id = uniqueId(`${type}_`),
  className,
  onChange,
  hasError,
  errorMessage,
  label,
  ...attr
}: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const isTypePassword = type === 'password';
  const inputType = isTypePassword && isVisible ? 'text' : type;
  const [hasContent, setHasContent] = useState(
    !!attr.defaultValue || !!attr.value || false,
  );
  const handleVisibilityChange = () => {
    setIsVisible(!isVisible);
  };
  let autocomplete = '';
  switch (type) {
    case 'password':
      autocomplete = 'current-password';
      break;
    case 'email':
      autocomplete = 'username';
      break;
  }

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    onChange?.(ev);
    if (ev.target.value.length > 0) {
      setHasContent(true);
    } else {
      setHasContent(false);
    }
  };

  return (
    <div className={st.container}>
      <div className={st.inputContainer}>
        <input
          {...attr}
          id={id}
          type={inputType}
          onChange={handleChange}
          data-has-error={hasError}
          className={classNames(st.input, className)}
          autoComplete={autocomplete}
        />
        {Boolean(label) && (
          <label
            className={st.label}
            htmlFor={id}
            data-is-supertext={hasContent}
          >
            {label}
          </label>
        )}
        {isTypePassword && (
          <Image
            className={st.visibilityIcon}
            src={isVisible ? visibility_on : visibility_off}
            alt={isVisible ? 'Hide password' : 'Show password'}
            onClick={handleVisibilityChange}
            height={24}
            width={24}
          />
        )}
        {/* {hasError && <Image alt={'Error'} src={errorRed} height={44} />} */}
      </div>
      {Boolean(errorMessage) && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
}
