import classNames from 'classnames';
import Link from 'next/link';
import { ReactNode, forwardRef } from 'react';
import ArrowTopRightIcon from '../../public/images/icons/arrowTopRight.svg';
import LoadingSpinner from '../../public/images/icons/loading-spinner.svg';
import st from './NewButton.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'submit' | 'button';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'highlight' | 'danger';
  isFullWidth?: boolean;
  isLoading?: boolean;
  size?: 'large' | 'small';
  shape?: 'rounded' | 'pill' | 'circle' | 'square';
  align?: 'center' | 'left' | 'right' | 'start' | 'end';
  isExternal?: boolean;
  children: string | ReactNode;
  className?: string;
  onClick?: (ev: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  href?: string;
  disabled?: boolean;
  showDisabled?: boolean;
}

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  function Button(
    {
      type = 'button',
      variant = 'primary',
      isFullWidth,
      isLoading,
      size = 'large',
      shape = 'rounded',
      align = 'center',
      isExternal,
      children,
      className,
      onClick,
      href = '',
      disabled,
      showDisabled,
      ...attr
    }: Props,
    ref,
  ) {
    const dataProps = {
      'data-variant': variant,
      'data-shape': shape,
      'data-align': align,
      'data-size': size,
      'data-is-full-width': isFullWidth,
      'data-is-loading': isLoading,
    };
    if (Boolean(href) && !disabled) {
      return (
        <Link
          href={href}
          passHref
          onClick={onClick}
          className={classNames(st.button, className)}
          data-is-disabled={showDisabled}
          ref={ref as React.Ref<HTMLAnchorElement>}
          {...dataProps}
        >
          <div className={st.content}>{children}</div>
          {isLoading && <LoadingSpinner className={st.loadingSpinner} />}
        </Link>
      );
    }

    return (
      <button
        type={type}
        onClick={onClick}
        className={classNames(st.button, className)}
        disabled={disabled}
        data-is-disabled={showDisabled}
        ref={ref as React.Ref<HTMLButtonElement>}
        {...dataProps}
        {...attr}
      >
        <div className={st.content}>
          {children}
          {isExternal && <ArrowTopRightIcon className={st.externalIcon} />}
        </div>
        {isLoading && <LoadingSpinner className={st.loadingSpinner} />}
      </button>
    );
  },
);

export default Button;
